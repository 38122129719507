"use client";

import { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import styles from "./HomeHero.module.scss";
import Image from "next/image";
import Play from "@/components/icons/Play";
import If from "@/components/If";

import Facebook from "@/components/icons/Facebook";
import Instagram from "@/components/icons/Instagram";
import LinkedIn from "@/components/icons/LinkedIn";

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

const PInP = ({ show = false }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  return (
    <button
      className={`pip-video fixed rounded overflow-hidden z-[3000] right-4 hidden md:block transition-all duration-300 bg-black ${
        show ? "show pointer-events-auto" : "hide pointer-events-none"
      } ${
        isFullScreen
          ? "top-[50%] -translate-y-[50%] right-[50%] translate-x-[50%] w-[80dvw] h-auto aspect-video"
          : "top-[60%] w-[346px] h-[196px]"
      }`}
      onClick={() => {
        setIsFullScreen(!isFullScreen);
      }}
    >
      <If condition={!isFullScreen}>
        <iframe
          src="https://player.vimeo.com/video/845937637?h=9725f49d2e&app_id=58479&title=0&byline=0&portrait=0&color=ffae39&controls=1&background=1&autoplay=1"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          loading="lazy"
          title={"Illuminus Brand Video"}
          className="object-cover w-[150%] h-[150%] relative top-[-25%] left-[-25%] pointer-events-none"
        />
        {/* <video
          src="/video/illuminus_sq.mp4"
          title="Illuminus Brand Video"
          className="object-cover w-full h-full"
          autoPlay
          playsInline
          muted
          preload="none"
          loop
        /> */}
        <div className="absolute bottom-3 left-3 w-[44px]">
          <Play width={44} />
        </div>
      </If>
      <If condition={isFullScreen}>
        <iframe
          src="https://player.vimeo.com/video/845937637?h=9725f49d2e&app_id=58479&title=0&byline=0&portrait=0&color=ffae39&controls=1&autoplay=1&loop=1"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          loading="lazy"
          title={"Illuminus Brand Video"}
          className="object-cover w-full h-full"
        />
        {/* <video
          src="/video/illuminus_sq.mp4"
          title="Illuminus Brand Video"
          className="object-cover w-full h-full"
          controls
          muted={false}
          autoPlay
          preload="none"
          loop
        /> */}
        <button
          className="absolute top-3 right-3 text-orange border-2 border-orange rounded-full p-2 font-bold"
          onClick={() => setIsFullScreen(false)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </If>
    </button>
  );
};

/**
 *
 * HomeHero
 *
 */
const HomeHero = ({ ...props }) => {
  const [loaded, setLoaded] = useState(false);
  const [showVideo, setShowVideo] = useState(true);
  const [progress, setProgress] = useState(0);

  const container = useRef(null);
  const image1 = useRef(null);
  const image2 = useRef(null);
  const image3 = useRef(null);
  const text1 = useRef(null);
  const text2 = useRef(null);
  const text3 = useRef(null);

  useGSAP(
    () => {
      if (!gsap) {
        return;
      }

      var tl = gsap.timeline({
        scrollTrigger: {
          trigger: container.current,
          pin: true, // pin the trigger element while active
          start: "top top", // when the top of the trigger hits the top of the viewport
          end: "bottom bottom", // end after scrolling 500px beyond the start
          scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
          onEnterBack: () => {
            setShowVideo(true);
          },
          onEnter: () => {
            setShowVideo(true);
          },
          onLeave: () => {
            setShowVideo(false);
          },
          onUpdate: (self) => {
            setProgress(self.progress.toFixed(3));
          },
          // snap: {
          //   snapTo: "labels", // snap to the closest label in the timeline
          //   duration: { min: 0.2, max: 3 }, // the snap animation should be at least 0.2 seconds, but no more than 3 seconds (determined by velocity)
          //   delay: 0.2, // wait 0.2 seconds from the last scroll event before doing the snapping
          //   ease: "power1.inOut", // the ease of the snap animation ("power3" by default)
          // },
        },
      });

      // tl.fromTo(
      //   image1.current,
      //   {
      //     opacity: 1,
      //   },
      //   {
      //     opacity: 0,
      //     duration: 1,
      //   }
      // );
      tl.fromTo(
        image2.current,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 1,
          delay: "+=0.75",
          ease: "power1.inOut",
        }
      );
      tl.fromTo(
        image3.current,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 1,
          delay: "-=0.75",
          ease: "power1.inOut",
        }
      );

      // gsap.to([image1.current], {
      //   opacity: "0",
      //   scrollTrigger: {
      //     trigger: topCurve1.current,
      //     start: "+=35%",
      //     end: "+=120%",
      //     scrub: 0.75,
      //   },
      // });
    },
    {
      dependencies: [
        container.current,
        image1.current,
        image2.current,
        image3.current,
        text1.current,
        text2.current,
        text3.current,
      ],
    }
  );
  return (
    <>
      <header
        className={`${styles["home-hero"]} h-[300dvh] overflow-hidden relative`}
        {...props}
        ref={container}
      >
        <div
          ref={image1}
          className="opacity-1 z-[1] absolute top-0 left-0  w-full h-screen"
        >
          <img
            src="/fpos/home-hero-1.jpg"
            alt="Home Hero"
            className="object-cover w-full h-full pointer-events-none"
          />
          <div
            className="left-0 top-[50%] -translate-y-[50%] text-white absolute opacity-1 px-gutter"
            ref={text1}
          >
            <h1 className="text-section-title uppercase font-bold">
              WELCOME TO ILLUMINUS
            </h1>
            <div className="flex gap-4 items-center">
              <a
                href={"https://www.facebook.com/liveilluminus"}
                className="text-sm text-white hover:text-orange transition-all duration-300"
                target="_blank"
                rel="noopener"
              >
                <span className="sr-only">Facebook</span>
                <Facebook width="25" />
              </a>
              <a
                href={"https://www.instagram.com/liveilluminus/"}
                className="text-sm text-white hover:text-orange transition-all duration-300"
                target="_blank"
                rel="noopener"
              >
                <span className="sr-only">Instagram</span>
                <Instagram width="25" />
              </a>

              <a
                href={"http://www.linkedin.com/company/liveilluminus"}
                className="text-sm text-white hover:text-orange transition-all duration-300"
                target="_blank"
                rel="noopener"
              >
                <span className="sr-only">LinkedIn</span>
                <LinkedIn width="25" />
              </a>
            </div>
          </div>
        </div>
        <div
          className="object-cover absolute top-0 left-0 w-full h-screen opacity-1 z-[2]  pointer-events-none"
          ref={image2}
        >
          <img
            src="/fpos/illuminus-home-2.jpg"
            alt="Home Hero"
            className="object-cover w-full h-full"
          />
          <div className="absolute left-0 top-[50%] -translate-y-[50%] opacity-1 text-white px-gutter max-w-full w-[1200px]">
            <p className=" text-large">
              Discover the future of senior living, where innovation meets
              comfort and care.
            </p>
            <p className=" text-large">
              Experience unparalleled senior lifestyle options designed to
              enrich the lives of older adults.
            </p>
          </div>
        </div>
        <div
          className="object-cover absolute top-0 left-0 w-full h-screen opacity-1 z-[3]  pointer-events-none"
          ref={image3}
        >
          <img
            src="/fpos/home-hero-3.jpg"
            alt="Home Hero"
            className="object-cover w-full h-full"
          />
          <div className="absolute left-0 top-[50%] -translate-y-[50%] opacity-1 text-white px-gutter">
            <p className=" text-large">
              Welcome to Illuminus – redefining senior living excellence.
            </p>
          </div>
        </div>
      </header>

      <PInP show={showVideo} />
      <div
        className={`w-full h-[15px] bg-orange fixed bottom-0 left-0 z-[50] ${
          showVideo ? "opacity-100" : "opacity-0"
        }`}
        style={{ transform: `scaleX(${progress})`, transformOrigin: "left" }}
      ></div>
    </>
  );
};

export default HomeHero;
